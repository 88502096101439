export enum Environment
{
    Test = 'test',
    Development = 'development',
    Production = 'production'
}

interface Config
{
    /** The app version */
    version: string;
    /** The app environment. */
    environment: Environment;
    /** The URL of the this Next.js website. */
    appUrl: string;
    /** The GraphQL API URL.  */
    apiUrl: string;
    /** Is the CDN enabled? */
    isCdnEnabled: boolean;
    /** The base URL of the AWS CDN. */
    cdnBaseUrl: string;
    /** The base URL for the /public directory. */
    publicDirBaseUrl: string;
    /** If CDN is enabled, this points to the CDN /public directory, otherwise to the servers's /public directory. */
    defaultPublicDirBaseUrl: string;
    /** The base name for the React Router (if it's used) */
    reactRouterBaseName?: string;
    /** A list of available languages. */
    languages: AppLanguage[]
    /** The default languge of the app. */
    defaultLanguage: AppLanguage;
    /** The Google Analytics tracking ID. */
    googleAnalyticsId?: string;
    /** The Google Universal Analytics tracking ID. */
    googleUniversalAnalyticsId?: string;
    /** The Google Ads tracking ID */
    googleAdsId?: string;
    /** The Google Sign in with Google client ID. */
    googleApiClientId?: string;
    /** The Facebook Pixel tracking ID. */
    facebookPixelId?: string;
    /** The Facebook App ID. */
    facebookAppId?: string;
    /** The distance from the top of the screen. */
    scrollSpyActivatorTreshold: number;
    /** The base URL of the Webflow website. */
    webflowBaseUrl: string;
    /** The Google ads conversion event id for the lead form submit. */
    googleAdsConversionIdFormSubmit: string;
    /** The Google Ads conversion event id for approving a lead */
    googleAdsConversionIdApproveLead: string;
}

let environment: Environment = process.env.NODE_ENV as Environment;
let isProduction = environment == Environment.Production;
let isCdnEnabled = parseInt(process.env['NEXT_PUBLIC_WEBFLOW_CDN_ENABLED'] as string) == 1;
let languages = [
    { code: 'de', name: 'Deutsch', locale: 'de_DE' },
    { code: 'en', name: 'English', locale: 'en_US' },
    { code: 'hu', name: 'Magyar', locale: 'hu_HU' },
    { code: 'ro', name: 'Română', locale: 'ro_RO' },
];

let config: Config =
{
    version: process.env['NEXT_PUBLIC_WEBFLOW_VERSION'] as string,
    environment: environment,
    appUrl: process.env['NEXT_PUBLIC_WEBFLOW_APP_URL'] as string,
    apiUrl: process.env['NEXT_PUBLIC_WEBFLOW_API_URL'] as string,
    isCdnEnabled: isCdnEnabled,
    cdnBaseUrl: process.env['NEXT_PUBLIC_WEBFLOW_CDN_BASE_URL'] as string,
    publicDirBaseUrl: process.env['NEXT_PUBLIC_WEBFLOW_APP_URL'] as string,
    defaultPublicDirBaseUrl: (isProduction && isCdnEnabled ? process.env['NEXT_PUBLIC_WEBFLOW_CDN_BASE_URL'] : process.env['NEXT_PUBLIC_WEBFLOW_APP_URL']) as string,
    reactRouterBaseName: process.env['NEXT_PUBLIC_WEBFLOW_REACT_ROUTER_BASENAME'] as string,
    languages: languages,
    defaultLanguage: languages.find(l => l.code == 'en')!,
    googleAnalyticsId: process.env['NEXT_PUBLIC_WEBFLOW_GOOGLE_ANALYTICS_ID'] as string,
    googleUniversalAnalyticsId: process.env['NEXT_PUBLIC_WEBFLOW_GOOGLE_UNIVERSAL_ANALYTICS_ID'] as string,
    googleAdsId: process.env['NEXT_PUBLIC_WEBFLOW_GOOGLE_ADS_ID'] as string,
    googleApiClientId: process.env['NEXT_PUBLIC_WEBFLOW_GOOGLE_API_CLIENT_ID'] as string,
    facebookPixelId: process.env['NEXT_PUBLIC_WEBFLOW_FACEBOOK_PIXEL_ID'] as string,
    facebookAppId: process.env['NEXT_PUBLIC_WEBFLOW_FACEBOOK_APP_ID'] as string,
    scrollSpyActivatorTreshold: 20,
    webflowBaseUrl: 'https://www.evyssavacations.com/',
    googleAdsConversionIdFormSubmit: 'JaWUCMWItq8YEMyl7rco',
    googleAdsConversionIdApproveLead: '' // for compatibility with the WebflowLead class
}

export interface Language
{
    /** Language code. Eg: en, hu, ro */
    code: string, 
    /** The name of the language in the given language. */
    name: string,
}

export interface AppLanguage extends Language
{
    /** A language local name. Eg: en_US */
    locale: string,
}

export default config;